import React, { useState, useRef } from "react";
import "./App.css";
import profilePic from "./profilePic.jpg";
import livieresDesktop from "./assets/livieresDesktop.mp4";
import livieresMobile from "./assets/livieresMobile.mp4";
import covidDesktop from "./assets/covid19py.mp4";
import covidMobile from "./assets/covidMobile.mp4";
import CBVA from "./assets/cbva.png";
import TFZ from "./assets/tfz.png";

function App() {
  const livieresDesktopRef = useRef(null);
  const livieresMobileRef = useRef(null);
  const covidDesktopRef = useRef(null);
  const covidMobileRef = useRef(null);
  //los refs se usan para acceder al DOM y cambiar el estado de reproduccion de cada elemento

  //al comienzo se muestra solo el video desktop y el movil no
  const [livieresgState, setLivieresgState] = useState({
    src: livieresDesktop,
    desktopDisplay: `block`,
    mobileDisplay: `none`,
  });

  //al comienzo se muestra solo el video desktop y el movil no
  const [covidState, setCovidState] = useState({
    src: covidDesktop,
    desktopDisplay: `block`,
    mobileDisplay: `none`,
  });

  //funcion para cambiar el source del video, y el estado de display de los videos de movil a desktop
  //y viceversa, se acciona con el boton del proyecto
  const toggleLivieresg = () => {
    if (livieresgState.src === livieresDesktop) {
      setLivieresgState({
        src: livieresMobile,
        desktopDisplay: `none`,
        mobileDisplay: `block`,
      });
      livieresDesktopRef.current.pause();
      livieresMobileRef.current.play();
    } else {
      setLivieresgState({
        src: livieresDesktop,
        desktopDisplay: `block`,
        mobileDisplay: `none`,
      });
      livieresDesktopRef.current.play();
      livieresMobileRef.current.pause();
    }
  };

  //funcion para cambiar el source del video, y el estado de display de los videos de movil a desktop
  //y viceversa, se acciona con el boton del proyecto
  const toggleCovid = () => {
    if (covidState.src === covidDesktop) {
      setCovidState({
        src: covidMobile,
        desktopDisplay: `none`,
        mobileDisplay: `block`,
      });
      covidDesktopRef.current.pause();
      covidMobileRef.current.play();
    } else {
      setCovidState({
        src: covidDesktop,
        desktopDisplay: `block`,
        mobileDisplay: `none`,
      });
      covidDesktopRef.current.play();
      covidMobileRef.current.pause();
    }
  };

  return (
    <div>
      <div className="profileSection">
        <div className="title">
          <div className="name">CHRISTIAN BENTELE</div>
          <div className="developer">DEVELOPER</div>
          <div className="description">
            <p>
              Soy estudiante de ingeniería informática en la Universidad
              Nacional de Asunción. Cofundador del
              <a href="https://estudioobliquo.com/"> Estudio Obliquo</a>. Me
              interesa el backend y el game development. Hablo español, inglés y
              alemán.
            </p>
          </div>
        </div>
        <div className="photoContainer">
          <img src={profilePic} alt=""></img>
          <a href="#mail">CONTACTO</a>
        </div>
      </div>
      <div className="tools">
        <p>Habilidades</p>
        <div className="toolsList">
          <div>React</div>
          <div>Javascript</div>
          <div>Node.js</div>
          <div>MongoDB</div>
          <div>DevOps</div>
          <div>HTML</div>
          <div>CSS</div>
        </div>
      </div>
      <div className="projectsTitle">
        <p>Proyectos</p>
      </div>
      <div className="projectsContainer">
        <div className="project">
          <div className="videoPlayer">
            <video
              width="100%"
              height="auto"
              muted
              loop
              style={{
                display: livieresgState.mobileDisplay,
              }}
              ref={livieresMobileRef}
            >
              <source src={livieresMobile} type="video/mp4" />
            </video>
            <video
              width="100%"
              height="auto"
              autoPlay
              muted
              loop
              ref={livieresDesktopRef}
              style={{
                display: livieresgState.desktopDisplay,
              }}
            >
              <source src={livieresDesktop} type="video/mp4" />
            </video>
          </div>
          <div className="projectDescription">
            <div className="projectTitle">
              <a href="https://www.livieresg.com.py">Livieres Guggiari</a>
            </div>
            <div className="projectInfo">
              Pagina web del estudio juridico, pagina responsive, multilenguaje
              y con la funcionalidad de busqueda
            </div>
            <button onClick={toggleLivieresg}>
              Ver version{" "}
              {livieresgState.src === livieresMobile ? "desktop" : "movil"}
            </button>
          </div>
        </div>
      </div>
      <div className="projectsContainer">
        <div className="project even">
          <div className="videoPlayer">
            <video
              width="100%"
              height="auto"
              muted
              loop
              ref={covidMobileRef}
              style={{
                display: covidState.mobileDisplay,
              }}
            >
              <source src={covidMobile} type="video/mp4" />
            </video>
            <video
              width="100%"
              height="auto"
              autoPlay
              loop
              muted
              ref={covidDesktopRef}
              style={{
                display: covidState.desktopDisplay,
              }}
            >
              <source src={covidDesktop} type="video/mp4" />
            </video>
          </div>
          <div className="projectDescription">
            <div className="projectTitle">
              <a href="https://covid.estudioobliquo.com">Covid-19</a>
            </div>
            <div className="projectInfo">
              Proyecto donde se muestran los datos actualizados sobre el covid
              19 en el Paraguay, con diferentes graficos que muestran el
              comportamiento de la enfermedad en el pais. Los datos estan
              almacenados en una planilla Excel que al actualizarse actualiza
              automaticamente la pagina web
            </div>
            <button onClick={toggleCovid}>
              Ver version {covidState === covidMobile ? "desktop" : "movil"}
            </button>
          </div>
        </div>
      </div>
      <div className="projectsContainer">
        <div className="project">
          <div className="videoPlayer">
            <img src={CBVA} alt="cbva"></img>
          </div>
          <div className="projectDescription">
            <div className="projectTitle">Bomberos Asunción</div>
            <div className="projectInfo">
              Sistema de inventario para el Cuerpo de Bomberos Voluntarios de
              Asunción
            </div>
          </div>
        </div>
      </div>
      <div className="projectsContainer">
        <div className="project even">
          <div className="videoPlayer">
            <img src={TFZ} alt="estudio tfz"></img>
          </div>
          <div className="projectDescription">
            <div className="projectTitle">
              <a href="https://www.estudiotfz.com">Estudio TFZ</a>
            </div>
            <div className="projectInfo">
              Página web del estudio jurídico Turbaux, Fuchs & Zorraquín
            </div>
          </div>
        </div>
      </div>
      <div className="contactSection">
        <p className="contactTitle">Contacto</p>
        <a id="mail" href="mailto:christianbentelem@gmail.com">
          christianbentelem@gmail.com
        </a>
      </div>
    </div>
  );
}

export default App;
